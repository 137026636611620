/* eslint-disable react/no-unknown-property */
/* fetchpriority is a non-standard attribute that is only supported by Chrome 56+ */
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export default function HeadPreloadLink({ href, as, fetchpriority = 'auto' }) {
  return (
    <Helmet>
      <link rel="preload" as={as} href={href} fetchpriority={fetchpriority} />
    </Helmet>
  );
}

HeadPreloadLink.propTypes = {
  href: PropTypes.string.isRequired,
  as: PropTypes.string.isRequired,
  fetchpriority: PropTypes.string,
};
