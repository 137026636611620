import classNames from 'clsx';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Link } from 'react-router-dom';
import PlayTile from 'src/common/components/containerItems/shared/PlayTile';
import ImageWithDefault from 'src/common/components/shared/ImageWithDefault';
import { defaultBrickImageSrc } from 'src/common/constants/imageUrls';
import connectWithPlayer from 'src/common/decorators/connectWithPlayer';
import assetUrl from 'src/common/utils/assetUrl';
import css from './tileLinks.module.scss';

class TileLink extends Component {
  static propTypes = {
    playerActions: PropTypes.object.isRequired,
    isSyncedWithPlayer: PropTypes.bool.isRequired,
    syncedPlayerStatus: PropTypes.string.isRequired,
    syncedPlayerAction: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    guideItem: PropTypes.object,
    canScrub: PropTypes.bool,
    href: PropTypes.string,
    imageUrl: PropTypes.string,
    isTwoColumns: PropTypes.bool,
    needsBorder: PropTypes.bool,
  };

  handlePlayAction = (event) => {
    event.preventDefault();
    const { syncedPlayerAction, playerActions, canScrub } = this.props;

    syncedPlayerAction(playerActions, canScrub);
  };

  render() {
    const {
      href,
      imageUrl,
      isTwoColumns,
      needsBorder,
      isSyncedWithPlayer,
      syncedPlayerStatus,
      canScrub,
      isMobile,
    } = this.props;

    const clickHandler = isSyncedWithPlayer ? this.handlePlayAction : noop;

    return (
      <div
        className={classNames(css.tileLink, {
          [css.tileLinkDouble]: isTwoColumns,
          [css.tileLinkSingle]: !isTwoColumns,
          [css.needsBorder]: needsBorder,
        })}
      >
        <Link to={href} onClick={clickHandler} data-testid="tileLinkOverlay">
          <PlayTile
            syncedPlayerStatus={syncedPlayerStatus}
            canScrub={canScrub}
            isMobile={isMobile}
          />
          <ImageWithDefault
            src={assetUrl(imageUrl)}
            alt={imageUrl}
            placeholderSrc={isTwoColumns ? defaultBrickImageSrc : null}
          />
        </Link>
      </div>
    );
  }
}

export default connectWithPlayer(TileLink);
