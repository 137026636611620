import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';

import { isLarge } from '../../utils/breakpoints';
import ImageBlock from '../shared/parallax/ImageBlock';

import { LANDING_LISTEN_NOW } from '../../constants/localizations/landing';
import commonCss from '../../styles/common.module.scss';
import isServer from '../../utils/isServer';
import { isIEorEdge } from '../../utils/userAgent/ieBrowserDetect';
import css from './parallax-headers.module.scss';

// export for testing
export const IE_FIXED_POSITION_TOP = 160;

// export for testing
export function getIEStyle(
  isMSBrowser,
  isRightSideFixed,
  pxScrollContainerEl,
  pxScrollTextContainerEl,
) {
  // called from ParallaxHeaders.prototype.handleScroll
  // IE fix changes positioning based on scroll position to mimic `position: sticky;`

  if (!isMSBrowser) return {};

  const containerHeight = pxScrollContainerEl.clientHeight;
  const containerScrollTop = pxScrollContainerEl.getBoundingClientRect().top;
  const textContainerHeight = pxScrollTextContainerEl.clientHeight;
  const pxScrollTopMinusHeight =
    containerScrollTop +
    containerHeight -
    (IE_FIXED_POSITION_TOP + textContainerHeight);
  const isAtBottomOfPxScroll =
    document.body.scrollTop >= pxScrollTopMinusHeight;
  // These checks are to determine whether the user has scrolled to the bottom, or past, the
  // parallax scroll container. This is only necessary for IE inline styling.

  let pxScrollTextStyle = {
    position: 'static',
  };

  if (isRightSideFixed) {
    pxScrollTextStyle = {
      position: 'fixed',
      paddingRight: 36,
      top: IE_FIXED_POSITION_TOP,
    };
  }
  if (isAtBottomOfPxScroll) {
    pxScrollTextStyle = {
      position: 'absolute',
      top: 'auto',
      bottom: 0,
    };
  }
  return pxScrollTextStyle;
}

function isInViewportParallax(el, isMSBrowser) {
  // called from ParallaxHeaders.prototype.handleScroll
  // Determines whether a particular element should be the focus of the parallax scroll header/text

  const OVERLAP_FACTOR = isMSBrowser ? 0.2 : 0.4; // multiplied by the window height

  const offset = el.getBoundingClientRect().top;
  const offsetWithOuterHeight = offset + el.offsetHeight;
  const windowScrollTop = document.body.scrollTop;
  const scrollTopAndFactorHeight =
    windowScrollTop + OVERLAP_FACTOR * window.innerHeight;

  return (
    offsetWithOuterHeight > windowScrollTop && offset < scrollTopAndFactorHeight
  );
}

function mapStateToProps(state) {
  return {
    userAgent: state.app.userAgent,
  };
}

class ParallaxHeaders extends Component {
  static propTypes = {
    breakpoint: PropTypes.number.isRequired,
    objects: PropTypes.array.isRequired,
    userAgent: PropTypes.string.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.state = {
      isMSBrowser: isIEorEdge(props.userAgent),
      activeTextIdx: 0,
      ieStyleAdjust: {},
    };
    if (!isServer() && isLarge(props.breakpoint)) {
      this.addScrollListener();
    }
  }

  componentDidUpdate(prevProps) {
    const { breakpoint } = this.props;

    if (isLarge(prevProps.breakpoint) && !isLarge(breakpoint)) {
      this.removeScrollListener();
      return;
    }

    if (!isLarge(prevProps.breakpoint) && isLarge(breakpoint)) {
      this.addScrollListener();
    }
  }

  componentWillUnmount() {
    document
      .getElementById('appRootScrollReset')
      .removeEventListener('scroll', this.handleScroll);
  }

  addScrollListener() {
    document
      .getElementById('appRootScrollReset')
      .addEventListener('scroll', this.handleScroll);
  }

  removeScrollListener() {
    document
      .getElementById('appRootScrollReset')
      .removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { objects } = this.props;
    const { isMSBrowser } = this.state;
    let isRightSideFixed = false;
    let activeTextIdx = 0;

    for (let i = 0; i < objects.length; i++) {
      if (isInViewportParallax(this[`p${i}`], isMSBrowser)) {
        activeTextIdx = i;
        isRightSideFixed = true;
      }
    }

    const ieStyleAdjust = getIEStyle(
      isMSBrowser,
      isRightSideFixed,
      this.pxScrollContainer,
      this.pxScrollTextContainer,
    );

    this.setState({
      activeTextIdx,
      ieStyleAdjust,
    });
  };

  render() {
    let content;
    const { objects, breakpoint } = this.props;
    const { getLocalizedText } = this.context;
    const { activeTextIdx, ieStyleAdjust } = this.state;

    if (isLarge(breakpoint)) {
      content = (
        <div
          ref={(rf) => {
            this.pxScrollContainer = rf;
          }}
          className={css.parallaxScrollContainer}
        >
          <div className={css.parallaxScrollImagesContainer}>
            {objects.map((obj, idx) => (
              <div
                key={`parallax-content-${idx}`}
                ref={(rf) => {
                  this[`p${idx}`] = rf;
                }}
                className={commonCss.clearfix}
              >
                <ImageBlock
                  dataTestId={obj.dataTestId}
                  isFirstImageSmall={obj.isFirstImageSmall}
                  imageBlock={obj.imageBlock}
                />
              </div>
            ))}
          </div>
          <div
            ref={(rf) => {
              this.pxScrollTextContainer = rf;
            }}
            className={css.parallaxScrollText}
            style={ieStyleAdjust}
          >
            {objects.map((obj, idx) => (
              <h1
                data-testid={`parallax-header-${obj.dataTestId}`}
                key={`parallax-header-${idx}`}
                className={classNames({ [css.active]: activeTextIdx === idx })}
              >
                {getLocalizedText(obj.header)}
              </h1>
            ))}
            {objects.map((obj, idx) => (
              <div key={`parallax-description-${idx}`}>
                <p
                  data-testid={`parallax-description-${obj.dataTestId}`}
                  className={classNames(css.verticalDescription, {
                    [css.active]: activeTextIdx === idx,
                  })}
                >
                  {getLocalizedText(objects[activeTextIdx].subtitle)}
                </p>
                <Link
                  data-testid={`parallax-listenNow-${obj.dataTestId}`}
                  to={objects[activeTextIdx].href}
                  className={classNames(css.verticalDescription, {
                    [css.active]: activeTextIdx === idx,
                  })}
                >
                  <div className={css.playIcon} />
                  {getLocalizedText(LANDING_LISTEN_NOW)}
                </Link>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      content = (
        <div className={css.parallaxScrollWrapperSmall}>
          {objects.map((obj, idx) => (
            <div
              className={css.parallaxScrollContainer}
              key={`parallax-unit-small-${idx}`}
            >
              <ImageBlock
                dataTestId={obj.dataTestId}
                isFirstImageSmall={obj.isFirstImageSmall}
                imageBlock={obj.imageBlock}
              />
              <div className={css.parallaxScrollTextSmall}>
                <h1 data-testid={`parallax-header-${obj.dataTestId}`}>
                  {getLocalizedText(obj.header)}
                </h1>
                <p data-testid={`parallax-description-${obj.dataTestId}`}>
                  {getLocalizedText(obj.subtitle)}
                </p>
                <Link
                  data-testid={`parallax-listenNow-${obj.dataTestId}`}
                  to={obj.href}
                  className={css.parallaxListenNowLink}
                >
                  <div className={css.playIcon} />
                  {getLocalizedText(LANDING_LISTEN_NOW)}
                </Link>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return content;
  }
}

export default connect(mapStateToProps)(ParallaxHeaders);
