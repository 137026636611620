import classNames from 'clsx';
import PropTypes from 'prop-types';

import PillButtonLink from './PillButtonLink';

import css from './pill-button.module.scss';

const CoralButtonLink = ({ className, ...otherProps }) => (
  <PillButtonLink
    className={classNames(className, css.coral)}
    {...otherProps}
  />
);

CoralButtonLink.propTypes = {
  className: PropTypes.string,
};

export default CoralButtonLink;
