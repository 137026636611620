import classNames from 'clsx';
import findIndex from 'lodash/findIndex';
import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectIsSubscriptionEnabled } from 'src/common/selectors/config';
import { selectIsUserSubscribed } from 'src/common/selectors/me';
import {
  logMobileDownloadClick,
  logSubscribeActivity,
  logWebActivity,
} from '../../actions/logging';
import mobileDownload from '../../constants/analytics/categoryActionLabel/mobileDownload';
import subscribe, {
  labels,
} from '../../constants/analytics/categoryActionLabel/subscribe';
import web from '../../constants/analytics/categoryActionLabel/web';
import {
  utmCampaignLandingPage,
  utmMedium,
  utmSource,
} from '../../constants/analytics/utm';
import {
  LANDING_SPLASH_HEADER_BACKGROUND,
  LANDING_SPLASH_HEADER_CTA_LINK,
  LANDING_SPLASH_HEADER_ENABLED,
} from '../../constants/experiments/config';
import {
  LANDING_DEVICES_PARAGRAPH,
  LANDING_DISCOVER_PARAGRAPH,
  LANDING_DOWNLOAD_ON_APPLE,
  LANDING_FINAL_CTA,
  LANDING_GET_IT_ON_GOOGLE,
  LANDING_LEAD_PARAGRAPH,
  LANDING_LISTEN_NOW,
  LANDING_SUGGEST_SEARCH_V2,
  LANDING_VERTICALS_AUDIOBOOKS_DESCRIPTION,
  LANDING_VERTICALS_MUSIC_DESCRIPTION,
  LANDING_VERTICALS_NEWS_DESCRIPTION,
  LANDING_VERTICALS_PODCASTS_DESCRIPTION,
  LANDING_VERTICALS_SPORTS_DESCRIPTION,
} from '../../constants/localizations/landing';
import {
  AUDIOBOOKS_UPPERCASE_PERIOD,
  DISCOVER_UPPERCASE,
  EVERYWHERE_UPPERCASE,
  GO_UPPERCASE,
  LISTEN_UPPERCASE,
  MORE_UPPERCASE,
  MUSIC_UPPERCASE_PERIOD,
  NEWS_UPPERCASE_PERIOD,
  PODCASTS_UPPERCASE_PERIOD,
  PREMIUM_UPPERCASE,
  SPORTS_RADIO_UPPERCASE_PERIOD,
} from '../../constants/localizations/shared';
import { UPSELL_START_FREE_TRIAL_BTN } from '../../constants/localizations/upsell';
import {
  AUDIOBOOKS_BROWSE_PATH,
  HOME_BROWSE_PATH,
  MUSIC_BROWSE_PATH,
  NEWS_BROWSE_PATH,
  PODCASTS_BROWSE_PATH,
  SPORTS_BROWSE_PATH,
} from '../../constants/paths';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectValidatedHeroCarouselItems } from '../../selectors/heroCarousel';
import { selectProducts } from '../../selectors/products';
import cssVariables from '../../styles/variables';
import { isMedium } from '../../utils/breakpoints';
import debounce from '../../utils/debounce';
import { googlePlayAppUrl, iTunesAppUrl } from '../../utils/downloadLinks';
import { behaviors } from '../../utils/guideItemTypes';
import billingPeriods from '../../utils/subscription/billingPeriod';
import createSubscribeLink from '../../utils/subscription/createSubscribeLink';
import { getDefaultSubscribeSkus } from '../../utils/subscription/getSkus';
import getSubscribeEventLabel from '../../utils/subscription/getSubscribeEventLabel';
import SeoHelmet from '../SeoHelmet';
import SearchBar from '../search/Searchbar';
import CoralButtonLink from '../shared/button/CoralButtonLink';
import OutlinedPillButtonLink from '../shared/button/OutlinedPillButtonLink';
import ExternalLink from '../shared/link/ExternalLink';
import TileLinks from '../shared/tileLinks/TileLinks';
import { createLandingPageAndroidDeepLinks } from '../utils/createAndroidDeepLinks';
import { createLandingPageInternationalSeoLinks } from '../utils/createInternationalSeoLinks';
import { createLandingPageMetaDescription } from '../utils/pageMetaDescriptions';
import { createLandingPageMetaKeywords } from '../utils/pageMetaKeywords';
import { createLandingPageTitle } from '../utils/pageTitles';
import HeadPreloadLink from './HeadPreloadLink';
import {
  Divider,
  LandingPageText,
  LandingStartListeningButton,
  NewLandingUpsell,
  PremiumOfferingTable,
  SectionHeader,
  SectionText,
  SplashHeader,
} from './LandingComponents';
import ParallaxHeaders from './ParallaxHeaders';
import WideHeader from './WideHeader';
import HeroCarousel from './carousel/HeroCarousel';
import css from './landing.module.scss';

const utmParams = {
  utm_medium: utmMedium,
  utm_source: utmSource,
  utm_campaign: utmCampaignLandingPage,
};

/**
 * This is the background image for the landing page header.
 * The mobile image is used for mobile and tablet breakpoints.
 * The desktop image is used for desktop breakpoints.
 * This provides us with a manual override for the splash header for any temporary campaigns.
 * Note: When the splash header experiment and splash header background are truthy, that image is used.
 *
 * When we want to revert to our default landing page header use:
 * assetUrl('assets/img/landing/pattern-background.png') for both mobile and desktop.
 * DON'T SET IN CSS SINCE WE NEED TO PRELOAD THE IMAGE.
 */
const landingHeaderBackgroundImageMap = {
  mobile:
    'https://cdn-cms.tunein.com/gemini/backgrounds/Big12Radio_WebHeader_Mobile.jpg',
  desktop:
    'https://cdn-cms.tunein.com/gemini/backgrounds/BIG12Radio_WebHeader_Background_NoLogos_3000x1146.jpg',
};

const seoLinks = [
  ...createLandingPageInternationalSeoLinks(),
  ...createLandingPageAndroidDeepLinks(),
];

function makeReduceDefaultSkusToProducts(products) {
  return (newProducts, sku) => {
    const product = products?.[sku];
    const { currencyCode, billingPeriod } = product || {};

    return {
      ...newProducts,
      currencyCode: newProducts.currencyCode || currencyCode,
      ...(billingPeriod === billingPeriods.monthly && { monthly: product }),
      ...(billingPeriod === billingPeriods.yearly && { yearly: product }),
    };
  };
}

/* eslint-disable max-len */
const parallaxScrollObjects = [
  {
    dataTestId: 'news',
    header: NEWS_UPPERCASE_PERIOD,
    subtitle: LANDING_VERTICALS_NEWS_DESCRIPTION,
    href: NEWS_BROWSE_PATH,
    imageBlock: [
      {
        guideItem: { actions: { play: { guideId: 's20407' } } },
        imageUrl: 'assets/img/landing/parallaxImages/cnn-large-v2.jpg',
        href: '/cnn/',
      },
      {
        guideItem: { actions: { play: { guideId: 's20431' } } },
        imageUrl: 'assets/img/shared/tiles/fox-news-radio-small.jpg',
        href: '/radio/FOX-News-Talk-s20431/',
        needsBorder: true,
      },
      {
        guideItem: { actions: { play: { guideId: 's110052' } } },
        imageUrl: 'assets/img/shared/tiles/cnbc-small.jpg',
        href: '/radio/CNBC-s110052/',
        needsBorder: true,
      },
      {
        guideItem: { actions: { play: { guideId: 's297990' } } },
        imageUrl: 'assets/img/shared/tiles/msnbc-square.jpg',
        href: '/radio/MSNBC-s297990/',
      },
    ],
    isFirstImageSmall: false,
  },
  {
    dataTestId: 'sports',
    header: SPORTS_RADIO_UPPERCASE_PERIOD,
    subtitle: LANDING_VERTICALS_SPORTS_DESCRIPTION,
    href: SPORTS_BROWSE_PATH,
    imageBlock: [
      {
        guideItem: { actions: { play: { guideId: '' } } },
        imageUrl: 'assets/img/shared/tiles/mlb-large.jpg',
        href: '/radio/Stream-Baseball-c16230079/',
      },
      {
        guideItem: { actions: { play: { guideId: '' } } },
        imageUrl: 'assets/img/shared/tiles/nhl-small-v2.jpg',
        href: '/radio/NHL-Radio--Stream-Hockey-Radio-c393481/',
      },
      {
        guideItem: { actions: { play: { guideId: 's22469' } } },
        imageUrl: 'assets/img/shared/tiles/espn-small.jpg',
        href: '/espnradio/',
      },
      {
        guideItem: { actions: { play: { guideId: '' } } },
        imageUrl: 'assets/img/shared/tiles/nascar-small.jpg',
        href: '/radio/NASCAR-Radio-c484192/',
      },
    ],
    isFirstImageSmall: false,
  },
  {
    dataTestId: 'music',
    header: MUSIC_UPPERCASE_PERIOD,
    subtitle: LANDING_VERTICALS_MUSIC_DESCRIPTION,
    href: MUSIC_BROWSE_PATH,
    imageBlock: [
      {
        guideItem: { actions: { play: { guideId: 's249995' } } },
        imageUrl: 'assets/img/shared/tiles/latin-hits-small.jpg',
        href: '/radio/Latin-Hits-s249995/',
      },
      {
        guideItem: { actions: { play: { guideId: 's224628' } } },
        imageUrl: 'assets/img/landing/parallaxImages/country-roads-v2.jpg',
        href: '/countryroads/',
      },
      {
        guideItem: { actions: { play: { guideId: 's242677' } } },
        imageUrl: 'assets/img/shared/tiles/todays-hits-large.jpg',
        href: '/todays-hits/',
      },
      {
        guideItem: { actions: { play: { guideId: 's249994' } } },
        imageUrl: 'assets/img/shared/tiles/classic-rock-hits-small.jpg',
        href: '/radio/Classic-Rock-Hits-s249994/',
      },
    ],
    isFirstImageSmall: true,
  },
  {
    dataTestId: 'audiobooks',
    header: AUDIOBOOKS_UPPERCASE_PERIOD,
    subtitle: LANDING_VERTICALS_AUDIOBOOKS_DESCRIPTION,
    href: AUDIOBOOKS_BROWSE_PATH,
    imageBlock: [
      {
        guideItem: { actions: { play: { guideId: 'p3638469' } } },
        imageUrl: 'assets/img/shared/tiles/Audiobooks-Dream.jpg',
        href: '/podcasts/Biography--Autobiography/It-Was-All-a-Dream-p3638469/',
      },
      {
        guideItem: { actions: { play: { guideId: 'p3661526' } } },
        imageUrl: 'assets/img/shared/tiles/truth-or-beard.jpeg',
        href: '/podcasts/Fiction---Gothic/Truth-or-Beard-p3661526/',
        needsBorder: true,
      },
      {
        guideItem: { actions: { play: { guideId: 'p3635062' } } },
        imageUrl: 'assets/img/shared/tiles/the-great-gatsby.jpeg',
        href: '/podcasts/Fiction---General/The-Great-Gatsby-p3635062/',
        needsBorder: true,
      },
      {
        guideItem: { actions: { play: { guideId: 'p3668994' } } },
        imageUrl:
          'assets/img/shared/tiles/this-is-not-about-david-bowie-by-fj-morris.jpg',
        href: '/podcasts/Fiction---Short-Stories/This-is-(not-about)-David-Bowie-by-FJ-Morris-p3668994/',
      },
    ],
    isFirstImageSmall: false,
  },
  {
    dataTestId: 'podcasts',
    header: PODCASTS_UPPERCASE_PERIOD,
    subtitle: LANDING_VERTICALS_PODCASTS_DESCRIPTION,
    href: PODCASTS_BROWSE_PATH,
    imageBlock: [
      {
        guideItem: { actions: { play: { guideId: '' } } },
        imageUrl: 'assets/img/shared/tiles/this-am-life-small.jpg',
        href: '/podcasts/Storytelling/This-American-Life-p28/',
      },
      {
        guideItem: { actions: { play: { guideId: '' } } },
        imageUrl: 'assets/img/shared/tiles/wow-medium.jpg',
        href: '/podcasts/Kids--Family-Podcasts/Wow-in-the-World-p1086001/',
      },
      {
        guideItem: { actions: { play: { guideId: '' } } },
        imageUrl: 'assets/img/shared/tiles/the-daily-large.jpg',
        href: '/podcasts/News--Politics-Podcasts/The-Daily-p952868/',
      },
      {
        guideItem: { actions: { play: { guideId: '' } } },
        imageUrl: 'assets/img/shared/tiles/npr-hidden-brain-small.jpg',
        href: '/podcasts/Science-Podcasts/Hidden-Brain-p787503/',
      },
    ],
    isFirstImageSmall: true,
  },
];
/* eslint-enable max-len */

const handleAppDownloadClick = debounce((logAction, label) => logAction(label));

class Landing extends Component {
  static propTypes = {
    breakpoint: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      logMobileDownloadClick: PropTypes.func.isRequired,
      logSubscribeActivity: PropTypes.func.isRequired,
      logWebActivity: PropTypes.func.isRequired,
    }),
    monthlyAndYearlyProducts: PropTypes.object.isRequired,
    carouselElementList: PropTypes.array.isRequired,
    isSubscriptionEnabled: PropTypes.bool.isRequired,
    isUserSubscribed: PropTypes.bool.isRequired,
    shouldShowPremiumUpsell: PropTypes.bool.isRequired,
    [LANDING_SPLASH_HEADER_ENABLED]: PropTypes.bool.isRequired,
    [LANDING_SPLASH_HEADER_CTA_LINK]: PropTypes.string.isRequired,
    [LANDING_SPLASH_HEADER_BACKGROUND]: PropTypes.string,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);
    this.handleCarouselChange = this.handleCarouselChange.bind(this);

    this.state = {
      carouselSelectedIndex: 0,
    };
  }

  componentDidMount() {
    const { actions, shouldShowPremiumUpsell } = this.props;

    if (shouldShowPremiumUpsell) {
      const { location } = this.context;
      let eventLabel = getSubscribeEventLabel(
        labels.landingTopUpsell,
        location,
      );
      actions.logSubscribeActivity(subscribe.actions.show, eventLabel);
      eventLabel = getSubscribeEventLabel(
        labels.landingGoPremiumUpsell,
        location,
      );
      actions.logSubscribeActivity(subscribe.actions.show, eventLabel);
      eventLabel = getSubscribeEventLabel(
        labels.landingFinalCtaUpsell,
        location,
      );
      actions.logSubscribeActivity(subscribe.actions.show, eventLabel);
      // Log carousel upsell show event for when the first slide is the upsell
      this.logCarouselShowEvent();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.shouldShowPremiumUpsell !== prevProps.shouldShowPremiumUpsell
    ) {
      // allowed within a condition, the warning is to help users be careful with the usage
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        carouselSelectedIndex: 0,
      });
    }
  }

  handleCarouselChange(currentIndex) {
    this.setState({
      carouselSelectedIndex: currentIndex,
    });
    this.logCarouselShowEvent();
  }

  logCarouselShowEvent() {
    const { actions, shouldShowPremiumUpsell } = this.props;
    const { location } = this.context;

    // Log carousel upsell show event every time the upsell is in view
    if (shouldShowPremiumUpsell) {
      const carouselUpsellIndex = findIndex(
        this.props.carouselElementList,
        (o) => o.isCarouselUpsell,
      );
      if (carouselUpsellIndex === this.state.carouselSelectedIndex) {
        const eventLabel = getSubscribeEventLabel(
          labels.landingCarouselUpsell,
          location,
        );
        actions.logSubscribeActivity(subscribe.actions.show, eventLabel);
      }
    }
  }

  render() {
    const {
      actions,
      breakpoint,
      carouselElementList,
      shouldShowPremiumUpsell,
      monthlyAndYearlyProducts,
      [LANDING_SPLASH_HEADER_ENABLED]: landingSplashHeaderEnabled,
      [LANDING_SPLASH_HEADER_BACKGROUND]: landingSplashHeaderBackground,
      [LANDING_SPLASH_HEADER_CTA_LINK]: landingSplashHeaderCTALink,
    } = this.props;
    const { getLocalizedText, location } = this.context;
    const { carouselSelectedIndex } = this.state;
    const headerBackgroundImage =
      landingSplashHeaderBackground ||
      (isMedium(breakpoint)
        ? landingHeaderBackgroundImageMap.desktop
        : landingHeaderBackgroundImageMap.mobile);
    const slideBackgroundColor = landingSplashHeaderEnabled
      ? cssVariables['--secondary-color-5']
      : get(
          carouselElementList[carouselSelectedIndex],
          'bgColor',
          cssVariables['--black'],
        );
    const topSectionStyles = {
      backgroundColor: slideBackgroundColor,
      ...(landingSplashHeaderEnabled && {
        backgroundImage: `url(${headerBackgroundImage})`,
      }),
    };
    const areColorsInverted = landingSplashHeaderEnabled
      ? true
      : slideBackgroundColor === cssVariables['--black'];
    const splashHeaderLinkTo =
      landingSplashHeaderCTALink &&
      landingSplashHeaderCTALink.includes(behaviors.subscribe)
        ? createSubscribeLink({
            source: subscribe.labels.landingTopUpsell,
            location,
          })
        : {
            pathname: landingSplashHeaderCTALink,
          };
    const landingStructuredData = {
      '@type': 'WebSite',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://tunein.com/search/?query={search_term_string}',
        'query-input': 'required name=search_term_string',
      },
    };

    return (
      <>
        <SeoHelmet
          title={createLandingPageTitle(getLocalizedText)}
          description={createLandingPageMetaDescription(getLocalizedText)}
          keywords={createLandingPageMetaKeywords()}
          links={seoLinks}
          additionalJsonLd={landingStructuredData}
          includeJsonLd
        />
        <HeadPreloadLink
          href={headerBackgroundImage}
          as="image"
          fetchpriority="high"
        />
        <div
          data-testid="landingTopSection"
          className={classNames(css.topSection, {
            [css.landingSplashHeaderBackground]: landingSplashHeaderEnabled,
          })}
          style={topSectionStyles}
        >
          <WideHeader
            logSubscribeActivity={this.props.actions.logSubscribeActivity}
            isUserSubscribed={this.props.isUserSubscribed}
            subscriptionEnabled={this.props.isSubscriptionEnabled}
            breakpoint={breakpoint}
            areColorsInverted={areColorsInverted}
          />
          {!landingSplashHeaderEnabled && (
            <HeroCarousel
              carouselElements={carouselElementList}
              selectedIndex={carouselSelectedIndex}
              handleCarouselChange={this.handleCarouselChange}
              logSubscribeActivity={this.props.actions.logSubscribeActivity}
              logWebActivity={this.props.actions.logWebActivity}
              areColorsInverted={areColorsInverted}
            />
          )}
          {landingSplashHeaderEnabled && (
            <SplashHeader
              linkTo={splashHeaderLinkTo}
              onClick={() =>
                actions.logWebActivity(
                  web.actions.tap,
                  web.labels.landingSplashHeader,
                )
              }
            />
          )}
        </div>
        <div className={css.searchBarContainer}>
          <SearchBar
            useLegacyIcon
            iconContainerClassName={css.searchIconContainer}
            placeholder={getLocalizedText(LANDING_SUGGEST_SEARCH_V2)}
            iconProps={{
              width: '26px',
              height: '26px',
              fill: cssVariables['--secondary-color-5'],
            }}
            searchBarClassName={css.searchBar}
          />
        </div>
        <div>
          <LandingPageText
            data-testid="homeLeadParagraph"
            text={getLocalizedText(LANDING_LEAD_PARAGRAPH)}
          />
          <LandingStartListeningButton
            id="homeStartListeningButton1"
            data-testid="startListeningButton1"
          />
        </div>
        {shouldShowPremiumUpsell && (
          <NewLandingUpsell
            linkTo={createSubscribeLink({
              source: subscribe.labels.landingTopUpsell,
              location,
            })}
            onClick={() =>
              actions.logSubscribeActivity(
                subscribe.actions.tap,
                subscribe.labels.landingTopUpsell,
              )
            }
          />
        )}
        <ParallaxHeaders
          breakpoint={breakpoint}
          objects={parallaxScrollObjects}
        />

        {shouldShowPremiumUpsell && (
          <div className={css.premiumOfferingsBackground}>
            <div className={css.premiumOfferingsContainer}>
              <SectionHeader
                data-testid="premiumHeaderText"
                headerLine1={getLocalizedText(GO_UPPERCASE)}
                headerLine2={getLocalizedText(PREMIUM_UPPERCASE)}
                headerLine1Color="white"
                headerLine2Color={cssVariables['--tunein-coral']}
              />

              <div className={css.premiumOfferings}>
                <PremiumOfferingTable
                  data-testid="premiumOfferingTable"
                  isFreeOffering
                  products={monthlyAndYearlyProducts}
                >
                  <OutlinedPillButtonLink
                    to={HOME_BROWSE_PATH}
                    id="homeStartListeningButton2"
                    data-testid="homeStartListeningButton2"
                    className={classNames(
                      css.button,
                      css.startListeningButton2,
                    )}
                    label={getLocalizedText(LANDING_LISTEN_NOW)}
                  />
                </PremiumOfferingTable>

                <PremiumOfferingTable
                  data-testid="premiumOfferingTable"
                  products={monthlyAndYearlyProducts}
                >
                  <CoralButtonLink
                    id="landingGoPremiumUpsell"
                    dataTestId="landingGoPremiumUpsell"
                    to={createSubscribeLink({
                      source: subscribe.labels.landingGoPremiumUpsell,
                      location,
                    })}
                    className={classNames(css.button, css.startFreeTrialButton)}
                    label={getLocalizedText(UPSELL_START_FREE_TRIAL_BTN)}
                    onClick={() =>
                      actions.logSubscribeActivity(
                        subscribe.actions.tap,
                        subscribe.labels.landingGoPremiumUpsell,
                      )
                    }
                  />
                </PremiumOfferingTable>
              </div>
            </div>
          </div>
        )}
        <div className={css.deviceOfferingsContainer}>
          <div data-testid="CEImages" className={css.deviceOfferingsImages} />

          <div className={css.deviceTextAndLinks}>
            <SectionHeader
              data-testid="CEHeaderText"
              headerLine1={getLocalizedText(LISTEN_UPPERCASE)}
              headerLine2={getLocalizedText(EVERYWHERE_UPPERCASE)}
              headerLine2Color={cssVariables['--primary-color-3']}
            />
            <SectionText
              data-testid="CEBodyText"
              className={css.devicesText}
              text={getLocalizedText(LANDING_DEVICES_PARAGRAPH)}
            />

            <div
              className={css.deviceLinksContainer}
              data-testid="landingDeviceLinksContainer"
            >
              <div className={css.appDownloadLink}>
                <ExternalLink
                  data-testid="homeDownloadApple"
                  href={iTunesAppUrl(utmParams)}
                >
                  <div
                    onClick={() => {
                      handleAppDownloadClick(
                        actions.logMobileDownloadClick,
                        mobileDownload.labels.ios,
                      );
                    }}
                  >
                    <span className={css.srOnly}>
                      {getLocalizedText(LANDING_DOWNLOAD_ON_APPLE)}
                    </span>
                    <div className={css.appleIcon} />
                  </div>
                </ExternalLink>
              </div>
              <div className={css.appDownloadLink}>
                <ExternalLink
                  data-testid="homeDownloadAndroid"
                  href={googlePlayAppUrl(utmParams)}
                >
                  <div
                    onClick={() => {
                      handleAppDownloadClick(
                        actions.logMobileDownloadClick,
                        mobileDownload.labels.android,
                      );
                    }}
                  >
                    <span className={css.srOnly}>
                      {getLocalizedText(LANDING_GET_IT_ON_GOOGLE)}
                    </span>
                    <div className={css.googlePlayIcon} />
                  </div>
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div className={css.discoverContainer}>
          <SectionHeader
            data-testid="homeDiscoverHeaderText"
            headerLine1={getLocalizedText(DISCOVER_UPPERCASE)}
            headerLine2={getLocalizedText(MORE_UPPERCASE)}
            headerLine2Color={cssVariables['--primary-color-4']}
          />
          <SectionText
            data-testid="homeDiscoverBodyText"
            className={css.sectionTextDiscover}
            text={getLocalizedText(LANDING_DISCOVER_PARAGRAPH)}
          />
        </div>

        <TileLinks />

        <div className={css.finalCta}>
          <p>{getLocalizedText(LANDING_FINAL_CTA)}</p>
          <div className={css.finalCtaLinksContainer}>
            <LandingStartListeningButton
              id="homeStartListeningButton3"
              data-testid="startListeningButton3"
              className={css.finalCtaListenNowButton}
            />
            {shouldShowPremiumUpsell && (
              <CoralButtonLink
                id="finalCtaStartFreeTrialButton"
                dataTestId="finalCtaStartFreeTrialButton"
                to={createSubscribeLink({
                  source: subscribe.labels.landingFinalCtaUpsell,
                  location,
                })}
                className={css.finalCtaStartFreeTrialButton}
                label={getLocalizedText(UPSELL_START_FREE_TRIAL_BTN)}
                onClick={() =>
                  actions.logSubscribeActivity(
                    subscribe.actions.tap,
                    subscribe.labels.landingFinalCtaUpsell,
                  )
                }
              />
            )}
          </div>
        </div>

        <div className={css.finalCtaDivider} data-testid="finalCtaDivider">
          <div data-testid="finalCtaDividerDiv" />
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {
    logMobileDownloadClick,
    logSubscribeActivity,
    logWebActivity,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export function mapStateToProps(state) {
  const products = selectProducts(state);
  const defaultSkus = getDefaultSubscribeSkus(state.config?.autoupdate);
  const isUserSubscribed = selectIsUserSubscribed(state);
  const isSubscriptionEnabled = selectIsSubscriptionEnabled(state);
  const carouselElementList = selectValidatedHeroCarouselItems(state);

  return {
    carouselElementList,
    isUserSubscribed,
    isSubscriptionEnabled,
    shouldShowPremiumUpsell: isSubscriptionEnabled && !isUserSubscribed,
    monthlyAndYearlyProducts: defaultSkus.reduce(
      makeReduceDefaultSkusToProducts(products),
      {},
    ),
  };
}

export default flow(
  connectWithExperiments([
    LANDING_SPLASH_HEADER_ENABLED,
    LANDING_SPLASH_HEADER_CTA_LINK,
    LANDING_SPLASH_HEADER_BACKGROUND,
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(Landing);
