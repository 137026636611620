function getIEVersion(userAgent) {
  if (!userAgent) {
    return false;
  }

  // parse IE version number out of userAgent
  const indexOfMsie = userAgent.indexOf('MSIE');

  if (indexOfMsie > 0) {
    return Number.parseInt(
      userAgent.substring(indexOfMsie + 5, userAgent.indexOf('.', indexOfMsie)),
      10,
    );
  }
  return userAgent.match(/Trident\/7\./) ? 11 : 0;
}

export function isIEorEdge(userAgent) {
  return getIEVersion(userAgent) > 0 || /Edge\/\d./i.test(userAgent); // test for Edge
}
