import classNames from 'clsx';
import tileLinks1 from 'src/data/landing/tileLinks1.json';
import tileLinks2 from 'src/data/landing/tileLinks2.json';
import TileLink from './TileLink';
import css from './tileLinks.module.scss';

const TileLinks = () => (
  <div className={css.tileLinksBackgroundContainer}>
    <div className={css.tileLinksContainer}>
      <div className={css.tileLinksBlock}>
        {tileLinks1.map((tileLink, idx) => (
          <TileLink
            key={tileLink.imageUrl}
            data-testid={`tileLinkGroupLeft-${idx}`}
            {...tileLink}
          />
        ))}
      </div>
      <div className={classNames(css.tileLinksBlock, css.secondTileLinksBlock)}>
        {tileLinks2.map((tileLink, idx) => (
          <TileLink
            key={tileLink.imageUrl}
            data-testid={`tileLinkGroupRight-${idx}`}
            {...tileLink}
          />
        ))}
      </div>
    </div>
  </div>
);

export default TileLinks;
