import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Link } from 'react-router-dom';
import PlayTile from 'src/common/components/containerItems/shared/PlayTile';
import ImageWithDefault from 'src/common/components/shared/ImageWithDefault';
import { isAbsoluteUrl } from 'src/common/components/utils/isAbsoluteUrl';
import connectWithPlayer from 'src/common/decorators/connectWithPlayer';
import assetUrl from 'src/common/utils/assetUrl';
import css from './carousel.module.scss';

export class CarouselTileLink extends Component {
  static propTypes = {
    playerActions: PropTypes.object.isRequired,
    isSyncedWithPlayer: PropTypes.bool.isRequired,
    syncedPlayerStatus: PropTypes.string.isRequired,
    syncedPlayerAction: PropTypes.func.isRequired,
    handleCarouselItemClick: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    guideItem: PropTypes.object,
    canScrub: PropTypes.bool,
    href: PropTypes.string,
    isActive: PropTypes.bool,
    imageUrl: PropTypes.string,
  };

  handlePlayAction = (event) => {
    event.preventDefault();
    const { syncedPlayerAction, playerActions, canScrub } = this.props;

    syncedPlayerAction(playerActions, canScrub);
  };

  clickHandler = (e) => {
    if (this.props.isSyncedWithPlayer) {
      this.handlePlayAction(e);
    }

    this.props.handleCarouselItemClick();
  };

  render() {
    const { href, isActive, syncedPlayerStatus, canScrub, isMobile } =
      this.props;

    let imageUrl = ''; // need this to trigger Default image in ImageWithDefault
    if (this.props.imageUrl) {
      imageUrl = `${assetUrl(this.props.imageUrl)}`;

      if (isAbsoluteUrl(this.props.imageUrl)) {
        imageUrl = `${this.props.imageUrl}`;
      }
    }

    return (
      <div
        className={classNames(css.tinyImg, css.tileLink, {
          [css.showTinyImg]: isActive,
        })}
      >
        <Link
          to={href}
          onClick={this.clickHandler}
          data-testid="carouselTileLinkOverlay"
          data-isactive={isActive}
        >
          <PlayTile
            syncedPlayerStatus={syncedPlayerStatus}
            canScrub={canScrub}
            isMobile={isMobile}
          />
          <ImageWithDefault src={imageUrl} alt={imageUrl} />
        </Link>
      </div>
    );
  }
}

export default connectWithPlayer(CarouselTileLink);
