import PropTypes from 'prop-types';
import { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';

import classNames from 'clsx';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import HeroImage from '../../shared/hero/HeroImage';
import HeroImageContainer from '../../shared/hero/HeroImageContainer';
import CarouselTileLink from './CarouselTileLink';
import css from './carousel.module.scss';

const carouselConfig = {
  className: 'landingCarousel',
  showArrows: false,
  showStatus: false,
  showThumbs: false,
  showIndicators: false,
  infiniteLoop: true,
  transitionTime: 300,
  interval: 5000,
};

export default class CarouselImageSlider extends Component {
  static propTypes = {
    handleOnChange: PropTypes.func.isRequired,
    carouselElements: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    handleCarouselItemClick: PropTypes.func.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.selectedIndex !== this.props.selectedIndex ||
      nextProps.carouselElements !== this.props.carouselElements ||
      nextProps.handleOnChange !== this.props.handleOnChange
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { handleOnChange, carouselElements, selectedIndex } = this.props;

    return (
      <HeroImageContainer>
        {carouselElements.map((element, i) => {
          const isActive = i === selectedIndex;

          if (element.tinyImgUrl) {
            return (
              <div
                key={element.tinyImgUrl}
                className={classNames(css.tinyImgWrapper, {
                  [css.layerUp]: isActive,
                })}
              >
                <div
                  data-testid="carouselTileLinkContainer"
                  className={classNames(css.tinyImgBgColor, {
                    [css.visible]: isActive,
                  })}
                  style={{ backgroundColor: element.bgColor }}
                />
                <CarouselTileLink
                  isActive={isActive}
                  imageUrl={element.tinyImgUrl}
                  href={element.href}
                  guideItem={element.guideItem}
                  handleCarouselItemClick={() =>
                    this.props.handleCarouselItemClick(i, element)
                  }
                />
              </div>
            );
          }
          return null;
        })}
        <Carousel
          {...carouselConfig}
          autoPlay={
            this.context.location.query.homecarouselautoscroll !== 'false'
          }
          onChange={handleOnChange}
          selectedItem={selectedIndex}
        >
          {carouselElements.map((element, i) => (
            <HeroImage
              key={element.imageUrl}
              dataTestId={`homeCarouselElement-${i}`}
              element={element}
              handleImageClick={() =>
                this.props.handleCarouselItemClick(i, element)
              }
            />
          ))}
        </Carousel>
      </HeroImageContainer>
    );
  }
}
