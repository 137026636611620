import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import cssVariables from 'src/common/styles/variables';
import subscribe, {
  labels,
} from '../../../constants/analytics/categoryActionLabel/subscribe';
import web from '../../../constants/analytics/categoryActionLabel/web';
import { UPSELL_START_FREE_TRIAL_BTN } from '../../../constants/localizations/upsell';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import getSubscribeEventLabel from '../../../utils/subscription/getSubscribeEventLabel';
import PillButtonLink from '../../shared/button/PillButtonLink';
import HeroHeader from '../../shared/hero/HeroHeader';
import HeroText from '../../shared/hero/HeroText';
import TuneInMarquee from '../../shared/svgIcons/TuneInMarquee';
import CarouselImageSlider from './CarouselImageSlider';
import css from './carousel.module.scss';

export default class HeroCarousel extends Component {
  static propTypes = {
    carouselElements: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    handleCarouselChange: PropTypes.func,
    logWebActivity: PropTypes.func.isRequired,
    logSubscribeActivity: PropTypes.func.isRequired,
    areColorsInverted: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCarouselItemClick = this.handleCarouselItemClick.bind(this);
  }

  handleCarouselItemClick(index, element) {
    if (element.isCarouselUpsell) {
      const { location } = this.context;
      const eventLabel = getSubscribeEventLabel(
        labels.landingCarouselUpsell,
        location,
      );
      this.props.logSubscribeActivity(subscribe.actions.tap, eventLabel);
    }
    const guideId = get(element, 'guideItem.actions.play.guideId', null);

    this.props.logWebActivity(
      web.actions.tap,
      web.labels.carousel + (index + 1),
      guideId,
    );
  }

  handleChange(index) {
    // pass the index up and let it come down as a prop
    this.props.handleCarouselChange(index);
  }

  render() {
    const { carouselElements, selectedIndex, areColorsInverted } = this.props;

    return (
      <HeroHeader dataTestId="carouselHeader">
        <div data-testid="homeLogo" className={css.logoContainer}>
          <TuneInMarquee
            fill={
              areColorsInverted
                ? cssVariables['--block']
                : cssVariables['--secondary-color-5']
            }
          />
        </div>
        <div className={css.textWrapper}>
          {carouselElements.map(
            (
              element,
              i, // slide out, fade in
            ) => (
              <div
                data-testid={`heroCarouselTextContainer${i}`}
                className={classNames(css.textContainer, {
                  [css.showText]: i === selectedIndex,
                })}
                key={`text-${i}`}
              >
                <HeroText
                  element={element}
                  handleLinkClick={() =>
                    this.handleCarouselItemClick(i, element)
                  }
                  areColorsInverted={areColorsInverted}
                />
                {element.isCarouselUpsell && (
                  <PillButtonLink
                    dataTestId="carouselUpsellButton"
                    id="carouselUpsellButton"
                    to={element.href}
                    className={css.button}
                    label={this.context.getLocalizedText(
                      UPSELL_START_FREE_TRIAL_BTN,
                    )}
                    onClick={() => this.handleCarouselItemClick(i, element)}
                  />
                )}
              </div>
            ),
          )}
        </div>
        <ul className={css.controlDots}>
          {carouselElements.map((element, i) => (
            <li
              className={classNames({
                [css.dotSelected]: i === selectedIndex,
                [css.white]: areColorsInverted,
              })}
              onClick={() => this.handleChange(i)}
              key={`carouselControlDot-${i}`}
              data-testid={`carouselControlDot-${i}`}
            />
          ))}
        </ul>
        <CarouselImageSlider
          carouselElements={carouselElements}
          handleOnChange={this.handleChange}
          selectedIndex={selectedIndex}
          handleCarouselItemClick={(i, element) =>
            this.handleCarouselItemClick(i, element)
          }
        />
      </HeroHeader>
    );
  }
}
